<template>
    <div class="container">
        <ol>
            <li><router-link to="/test/menu-reload">사용자 권한/메뉴 갱신</router-link></li>
            <li><router-link to="/test/pagination">페이징 처리</router-link></li>
            <li><router-link to="/test/save-dtm-file-as-dtms">dtm 파일을 dtms로 저장</router-link></li>
            <li><router-link to="/test/canvas-template">캔버스 템플릿 팝업</router-link></li>
			<li><router-link to="/test/openapi">Open API</router-link></li>
			<li><router-link to="/test/braille">리블루이스 점역</router-link></li>
			<li><router-link to="/test/session">통합인증 세션</router-link></li>
        </ol>
    </div>
</template>
<script>
	import { $axios, $session } from "dot-framework";
	export default
    {
		data() {
			return {
				items: []
			}
		},
		methods:
		{
			btnClick()
			{
				alert("실행했습니다.");
			},
			async getRoomList()
            {
                let compNo = $session.getCompNo(); // 업체번호
				let userNo = $session.getUserNo(); // 로그인 사용자번호

                let url = "/class-app/v1/comps/" + compNo + "/classes";
				let param = "?OWNER_USER_NO=" + userNo;
				let response = await $axios.get(url + param);
                if (response.status == 200) {
                    this.items = response.data.items;
                }
			}
		},
	}
</script>
<style>
</style>
